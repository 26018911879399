<template>
  <div class="userList">
    <searchBar :formItems="formItems" @search="search" />
    <div style="margin-bottom: 10px">
      <el-button type="primary" @click="add()">
        <i class="el-icon-plus" />新增
      </el-button>
    </div>
    <div class="tableBox">
      <el-table
        :data="tableData"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center' }"
        border
        style="width: 100%"
      >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          width="80"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="nickName" label="昵称"></el-table-column>
        <el-table-column prop="phone" label="受邀请人手机号"></el-table-column>
        <el-table-column
          prop="interviewLocation"
          label="邀约地址"
        ></el-table-column>
        <el-table-column prop="inviteStatus" label="邀请状态">
          <template slot-scope="scope">{{
            scope.row.inviteStatus === "notified" ? "已通知" : "已完成"
          }}</template>
        </el-table-column>

        <el-table-column prop="appointmentTime" label="邀约时间">
          <template slot-scope="scope">{{
            scope.row.appointmentTime | formatTime("YMDHMS")
          }}</template>
        </el-table-column>
        <el-table-column prop label="操作" width="120">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editData(scope.row)"
              >编辑</el-button
            >
            <el-button @click="deleteData(scope.row)" type="text" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fenye">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <UserDialog ref="dialog" @search="initPage" />
  </div>
</template>

<script>
import searchBar from "@/components/SearchBar/index";
import UserDialog from "./components/dialog.vue";
export default {
  components: { searchBar, UserDialog },
  data() {
    return {
      page: {
        page: 1,
        pageSize: 5,
      },
      params: {},
      total: 0,
      tableData: [],
      formItems: [
        // {
        //   label: "",
        //   type: "el-select",
        //   fieldName: "sex",
        //   data: [
        //     { label: "男", value: 0 },
        //     { label: "女", value: 1 },
        //   ],
        //   attrs: {
        //     placeholder: "性别",
        //   },
        // },
        {
          label: "",
          type: "el-input",
          fieldName: "nickName",
          attrs: {
            placeholder: "昵称",
          },
        },
        {
          label: "",
          type: "el-input",
          fieldName: "phone",
          attrs: {
            placeholder: "手机号",
          },
        },
      ],
    };
  },
  methods: {
    indexMethod(index) {
      return index + 1 + (this.page.page - 1) * this.page.pageSize;
    },
    handleSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.initPage();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.initPage();
    },
    search(opt) {
      //搜索
      this.params = opt;
      this.initPage();
    },
    initPage() {
      this.getAjax(
        "api/admin/sys/main/invite/list",
        { ...this.params, ...this.page },
        "get"
      ).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    add() {
      this.$refs.dialog.visible = true;
    },
    editData(item) {
      this.$refs.dialog.getInfo(item.id);
    },
    deleteData(item) {
      this.$confirm("确定删除吗？").then(() => {
        this.getAjax("api/delUser", { id: item.id }, "post").then((res) => {
          this.$message.success("删除成功");
          this.initPage();
        });
      });
    },
  },
  mounted() {
    this.initPage();
  },
};
</script>

<style lang="less"></style>
